import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SlicesRenderer from '../components/SlicesRenderer';
import { Container } from '../components/elements';
import SEO from '../components/SEO/';

import TopBackgroundShape from '../components/TopBackgroundShape';
import BottomBackgroundShape from '../components/BottomBackgroundShape';

const PageBody = ({ document }) => (
  <>
    <SlicesRenderer slices={document.body} />
  </>
);

PageBody.propTypes = {
  document: PropTypes.object.isRequired
};

const PageTemplate = ({ data }) => {
  const {
    prismicLayout: { data: layout },
    prismicPage: { data: page }
  } = data;
  const {
    top_background: topBackground,
    bottom_background: bottomBackground
  } = page;

  if (!layout || !page) return null;

  const {
    meta_title: { text: metaTitle },
    meta_description: { text: metaDescritpion }
  } = page;

  return (
    <Layout layout={layout} siteMetadata={data.site.siteMetadata} customSEO>
      <SEO title={metaTitle} desc={metaDescritpion} withJsonLd={false} />
      {topBackground && topBackground === 'Yes' && <TopBackgroundShape />}
      <Container width="100%" pt={170} px="15px" style={{ overflow: 'hidden' }}>
        <PageBody document={page} />
      </Container>
      {bottomBackground && bottomBackground === 'Yes' && (
        <BottomBackgroundShape />
      )}
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export default PageTemplate;

export const query = graphql`
  query PageBySlug($uid: String!) {
    ...LayoutFragment
    site {
      siteMetadata {
        title
        author
      }
    }
    prismicPage(uid: { eq: $uid }) {
      data {
        top_background
        bottom_background
        title {
          html
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        body {
          ... on PrismicPageBodyCtaBanner {
            id
            slice_type
            primary {
              action_label
              heading
              subheading
              action_url {
                link_type
                url
                target
                uid
                type
              }
            }
          }
          ... on PrismicPageBodyHeroBanner {
            id
            slice_type
            primary {
              heading
              subheading
              button_label
              button_link {
                link_type
                uid
                url
                target
                type
              }
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyImageStrip {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              image {
                alt
                localFile {
                  publicURL
                }
              }
              link {
                url
                target
                link_type
              }
            }
          }
          ... on PrismicPageBodyServices {
            id
            slice_type
            items {
              image {
                alt
                localFile {
                  publicURL
                }
              }
              service_name {
                html
              }
              description
            }
          }
          ... on PrismicPageBodyTestimonials {
            id
            slice_type
            primary {
              heading
            }
            items {
              job_title
              company
              website_url
              name
              quote
              photo {
                alt
                localFile {
                  childImageSharp {
                    fixed(width: 50, height: 50, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyTechStack {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              tech_stack_card {
                document {
                  ... on PrismicTechStackCard {
                    id
                    data {
                      title
                      body {
                        items {
                          gallery_image {
                            alt
                            url
                          }
                          website_url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageBodyWorkflow {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              title
              description
              icon {
                alt
                url
              }
            }
          }
          ... on PrismicPageBodyFeatures {
            id
            slice_type
            primary {
              heading
              pre_heading
            }
            items {
              title
              description
              icon {
                alt
                url
              }
            }
          }
          ... on PrismicPageBodyTextBlock {
            id
            slice_type
            primary {
              title1 {
                text
              }
              paragraph {
                html
              }
            }
          }
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              content {
                html
              }
              inverted
            }
          }
          ... on PrismicPageBodyContactForm {
            id
            slice_type
            primary {
              heading
              subheading
            }
          }
        }
      }
    }
  }
`;
